import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Container, Header, Image } from 'semantic-ui-react';

export default class Heading extends PureComponent {
  static propTypes = {
    mobile: PropTypes.bool
  }

  render() {
    const { mobile } = this.props;

    return (
      <Container textAlign="left">
        <div
          style={{
            position: 'absolute',
            bottom: '350px',
            textAlign: 'center',
            marginTop: '3em',
            marginLeft: mobile ? '0' : '3em'
          }}
        >
          <Image
            src="/static/images/logo_no_margin.png"
            size="medium"
            style={{ maxWidth: '300px' }}
          />
          <Header
            as="h1"
            content="Providing Practical People & Workplace Solutions"
            style={{
              color: '#43525A',
              fontSize: mobile ? '1.5em' : '2.6em',
              fontWeight: 'normal'
            }}
          />
        </div>
      </Container>
    );
  }
}
