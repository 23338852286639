import React from 'react';
import ReactDOM from 'react-dom';
import { hot } from 'react-hot-loader';
import { Home } from './containers';

const App = hot(module)(() => (
  <div style={{ backgroundColor: '#F6F6F6' }}>
    <Home />
  </div>
));

ReactDOM.render(<App />, document.getElementById('app'));
