import React from 'react';
import { Segment, Header } from 'semantic-ui-react';

export default () => (
  <Segment
    vertical
    style={{
      margin: '0 auto 5em',
      minHeight: '700px',
      backgroundImage: 'url("static/images/team/meet_the_team.png")',
      backgroundPosition: 'center',
      backgroundAttachment: 'fixed',
      backgroundSize: 'cover',
      border: 'none'
    }}
  >
    <div
      style={{
        margin: '5px 15px',
        border: '5px solid #b69d51',
        minHeight: '700px'
      }}
    />
    <div style={{ padding: '40px', position: 'absolute', top: '30%' }}>
      <Header
        as="h1"
        style={{ color: 'white', fontSize: '5em', fontWeight: '100' }}
      >
        Meet <span style={{ color: '#d5ac5a' }}>the Team</span>
      </Header>
      <Header
        as="h3"
        style={{
          color: 'white',
          fontWeight: '100',
          fontSize: '1.9em',
          lineHeight: '1.5em',
          maxWidth: '800px'
        }}
      >
        Ingenium is led by a team who are committed to delivering innovative
        solutions that work and making real impact in every assignment
      </Header>
    </div>
  </Segment>
);
