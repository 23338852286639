import React from 'react';
import { Header } from 'semantic-ui-react';
import { PersonStory } from '../../components';

const IMAGE_SRC = '/static/images/person/selina_chean.jpg';
const INITIAL = 'S';
const LEFT_NAME = 'SELINA';
const RIGHT_NAME = 'CHEAN';

const DESCRIPTION_STYLE = {
  color: '#43525A',
  fontWeight: '400',
  fontSize: '1.1em',
  lineHeight: '1.5em'
};

export default () => (
  <PersonStory
    invertedColor
    initial={INITIAL}
    imageSrc={IMAGE_SRC}
    title={LEFT_NAME}
    titleRight={RIGHT_NAME}
    description={(
      <div>
        <Header as="h3" style={DESCRIPTION_STYLE}>
          Selina has extensive experience directing and supporting HR
          and organisational initiatives for Malaysian conglomerates,
          GLCs & MNCs across a range of industry sectors, including
          financial services, energy, ICT & professional services and
          utilities. Selina spent a large part of her earlier professional
          life in HR consulting with PriceWaterhouseCoopers and moved on
          from there to senior HR executive roles with the AmBank Group,
          Wasco Energy Group and Telekom Malaysia.
        </Header>
        <Header as="h3" style={DESCRIPTION_STYLE}>
          Her areas of specialisation include
          &nbsp;
          <strong>
            HR & Talent Strategy, Talent & Leadership Development,
            Culture & Workplace Transformation, HR Effectiveness and
            Change Management.
          </strong>
          &nbsp;
          Her career, that spans both consulting and corporate
          experience, gives her a balanced perspective and
          appreciation for robust strategy, practical design and
          effective implementation.
        </Header>
        <Header as="h3" style={DESCRIPTION_STYLE}>
          Selina is passionate about working with organisations to
          &nbsp;
          <strong>create energising workplaces</strong>
          &nbsp;
          wherein people feel
          &nbsp;
          <strong>engaged and empowered</strong>
          &nbsp;
          to achieve their full potential and to
          &nbsp;
          <strong>deliver their best performance</strong>.
        </Header>
        <Header as="h3" style={DESCRIPTION_STYLE}>
          Selina read Law in the University of Wales, College of
          Cardiff and is a Master of Commerce, from the University of
          New South Wales, Australia, with a double major in Economics
          and Organisational Behavior. She is certified to interpret
          Hogan Assessment Systems and is also an ICF (International
          Coaching Federation) certified coach.
        </Header>
      </div>
    )}
  />
);
