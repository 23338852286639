import React from 'react';
import { Header } from 'semantic-ui-react';
import { PersonStory } from '../../components';

const IMAGE_SRC = '/static/images/person/yap_swee_pheng.jpg';
const INITIAL = 'Y';
const LEFT_NAME = 'YAP';
const RIGHT_NAME = 'SWEE PHENG';

const DESCRIPTION_STYLE = {
  color: '#43525A',
  fontWeight: '400',
  fontSize: '1.1em',
  lineHeight: '1.5em'
};

export default () => (
  <PersonStory
    initial={INITIAL}
    imageSrc={IMAGE_SRC}
    title={LEFT_NAME}
    titleRight={RIGHT_NAME}
    titleLeftMargin="-1em"
    description={(
      <div>
        <Header as="h3" style={DESCRIPTION_STYLE}>
          Swee Pheng has extensive corporate experience in leading
          Human Resource and Customer Experience teams in various
          industries - Hospitality, Financial Services, BPO, Telco and
          Professional Services.
        </Header>
        <Header as="h3" style={DESCRIPTION_STYLE}>
          Some of the organisations she has worked in are Towers
          Watson, DiGi Telecommunications, Alliance Bank, UOB Bank,
          Hong Leong Bank, Shangri-La’s Rasa Sayang Resort and Resorts
          World Bhd.
        </Header>
        <Header as="h3" style={DESCRIPTION_STYLE}>
          She is passionate about and therefore
          &nbsp;
          <strong>
            specializes in employee & customer engagement, people
            development, culture transformation, change management and
            employee communications.
          </strong>
          &nbsp;
          Swee Pheng has delivered client projects for both GLCs and
          MNCs.
        </Header>
        <Header as="h3" style={DESCRIPTION_STYLE}>
          She currently focuses her energy on
          &nbsp;
          <strong>
            facilitating workshops for Leadership development,
            people engagement
          </strong>
          &nbsp;
          and
          <strong>
            consulting on culture transformation projects
          </strong>
          .
        </Header>
        <Header as="h3" style={DESCRIPTION_STYLE}>
          Swee Pheng holds a degree in Business Management from the
          University of Toronto, Canada.
        </Header>
      </div>
    )}
  />
);
