import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Segment, Grid } from 'semantic-ui-react';
import MobileView from '../Responsive/MobileView';

export default class BannerMobile extends PureComponent {
  static propTypes = {
    renderTitle: PropTypes.func.isRequired,
    imageSrc: PropTypes.string.isRequired,
    bigTitle: PropTypes.bool
  };

  render() {
    const { bigTitle, renderTitle, imageSrc } = this.props;
    
    return (
      <MobileView>
        <Segment
          vertical
          style={{
            margin: '0 auto 5em',
            minHeight: '500px',
            backgroundImage: `url(${imageSrc})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundAttachment: 'fixed',
            border: 'none'
          }}
        >
          <Grid stackable verticalAlign="middle">
            <Grid.Row style={{ padding: '0' }}>
              <Grid.Column width={16}>
                <div
                  style={{
                    maxWidth: '1200px',
                    padding: bigTitle ? '0' : '20px'
                  }}
                >
                  {renderTitle(false)}
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </MobileView>
    );
  }
}
