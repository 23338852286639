import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Button, Container, Image, Grid } from 'semantic-ui-react';
import DesktopView from '../Responsive/DesktopView';

const BUTTON_TEXT = 'Learn more';

export default class Desktop extends PureComponent {
  static propTypes = {
    leftLogoSrc: PropTypes.string.isRequired,
    leftDescription: PropTypes.string.isRequired,
    leftLinkHref: PropTypes.string.isRequired,
    rightLogoSrc: PropTypes.string.isRequired,
    rightDescription: PropTypes.string.isRequired,
    rightLinkHref: PropTypes.string.isRequired
  };

  renderLeftSection() {
    const { leftLogoSrc, leftDescription, leftLinkHref } = this.props;

    return (
      <Grid.Column style={{ margin: '50px 0' }}>
        <Image
          src={leftLogoSrc}
          style={{
            display: 'inline-block',
            margin: '80px 20px 50px',
            maxWidth: '300px',
            maxHeight: '110px'
          }}
        />
        <p
          style={{
            color: '#606b71',
            fontSize: '1.2em',
            lineHeight: '1.5em',
            minHeight: '180px'
          }}
        >
          { leftDescription }
        </p>
        <Button
          color="brown"
          inverted
          style={{ borderRadius: '5em' }}
          href={leftLinkHref}
          target="_blank"
        >
          { BUTTON_TEXT }
        </Button>
      </Grid.Column>
    );
  }

  renderRightSection() {
    const { rightLogoSrc, rightDescription, rightLinkHref } = this.props;

    return (
      <Grid.Column style={{ margin: '50px 0' }}>
        <Image
          src={rightLogoSrc}
          style={{
            display: 'inline-block',
            margin: '20px',
            maxWidth: '200px',
            maxHeight: '200px'
          }}
        />
        <p
          style={{
            color: '#606b71',
            fontSize: '1.2em',
            lineHeight: '1.5em',
            minHeight: '180px'
          }}
        >
          { rightDescription }
        </p>
        <Button
          color="violet"
          inverted
          style={{ borderRadius: '5em' }}
          href={rightLinkHref}
          target="_blank"
        >
          { BUTTON_TEXT }
        </Button>
      </Grid.Column>
    );
  }

  render() {
    return (
      <DesktopView>
        <Container style={{ textAlign: 'center', marginBottom: '5em' }}>
          <Grid columns="equal">
            <Grid.Row>
              {this.renderLeftSection()}
              <Grid.Column
                style={{
                  backgroundColor: 'lightgrey',
                  maxWidth: '0.5px',
                  padding: '1px',
                  margin: '7em 3em'
                }}
              />
              {this.renderRightSection()}
            </Grid.Row>
          </Grid>
        </Container>
      </DesktopView>
    );
  }
}
