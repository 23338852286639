import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Segment, Grid } from 'semantic-ui-react';
import DesktopView from '../Responsive/DesktopView';

export default class BannerDesktop extends PureComponent {
  static propTypes = {
    renderTitle: PropTypes.func.isRequired,
    imageSrc: PropTypes.string.isRequired
  };

  render() {
    const { imageSrc, renderTitle } = this.props;
    
    return (
      <DesktopView>
        <Segment
          vertical
          style={{
            margin: '0 auto 5em',
            minHeight: '500px',
            backgroundImage: `url(${imageSrc})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundAttachment: 'fixed',
            border: 'none'
          }}
        >
          <Grid stackable verticalAlign="middle">
            <Grid.Row style={{ padding: '0' }}>
              <Grid.Column width={16}>
                <div style={{ maxWidth: '1200px', padding: '40px' }}>
                  {renderTitle()}
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </DesktopView>
    );
  }
}
