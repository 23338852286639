import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Header } from 'semantic-ui-react';

const TITLE_STYLE = {
  color: '#43525A',
  fontWeight: '600',
  fontSize: '1.2em'
};

const DESCRIPTION_STYLE = {
  color: '#606b71',
  fontWeight: '400',
  fontSize: '1.2em',
  lineHeight: '1.5em',
  marginTop: '1em'
};

export default class Section extends PureComponent {
  static propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
  };

  render() {
    const { title, description } = this.props;
    return (
      <div style={{ marginTop: '2em' }}>
        <Header style={TITLE_STYLE}>
          {title}
        </Header>
        <Header style={DESCRIPTION_STYLE}>
          {description}
        </Header>
      </div>
    );
  }
}
