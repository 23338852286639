import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Image, Container, Header, Segment, Grid, Responsive } from 'semantic-ui-react';

export default class PersonStory extends PureComponent {
  static propTypes = {
    initial: PropTypes.string,
    imageSrc: PropTypes.string.isRequired,
    imagePosition: PropTypes.oneOf(['left', 'right']),
    title: PropTypes.string.isRequired,
    titleRight: PropTypes.string.isRequired,
    titleLeftMargin: PropTypes.string,
    descriptions: PropTypes.arrayOf(PropTypes.string),
    description: PropTypes.node,
    invertedColor: PropTypes.bool
  };

  static defaultProps = {
    imagePosition: 'left',
    titleLeftMargin: '-1em'
  };

  renderBox(golden = true) {
    const { imagePosition } = this.props;
    return (
      <div
        style={{
          opacity: '0.5',
          position: 'absolute',
          marginTop: '-50px',
          marginLeft: imagePosition === 'left' ? '-100px' : '800px',
          height: '500px',
          width: '300px',
          border: `10px solid ${golden ? '#b8892c' : 'black'}`
        }}
      />
    );
  }

  renderInitial() {
    const { initial } = this.props;
    if (!initial) {
      return null;
    }
    return (
      <span
        style={{
          color: '#c1c1c1',
          fontSize: '80em',
          opacity: '0.1',
          position: 'absolute',
          marginTop: '100px'
        }}
      >
        {initial}
      </span>
    );
  }

  renderImage(forDesktop = true) {
    const { imageSrc } = this.props;
    return (
      <Image
        src={imageSrc}
        style={{
          padding: '10px',
          margin: '0',
          minHeight: '300px',
          minWidth: forDesktop ? '300px' : '300px',
          objectFit: 'cover',
          objectPosition: 'center'
        }}
      />
    );
  }

  renderTitle(forDesktop = true) {
    const { title, titleRight, titleLeftMargin, invertedColor } = this.props;
    return (
      <Header
        as="h1"
        style={{
          fontSize: forDesktop ? '4.5em' : '2.7em',
          fontWeight: '1200',
          marginLeft: forDesktop ? titleLeftMargin : '0',
          marginBottom: '0.5em'
        }}
      >
        <span style={{ color: invertedColor ? '#b8892c' : '#43525A' }}>
          {title}
        </span>
        <span
          style={{
            marginLeft: '0.2em',
            color: invertedColor ? '#43525A' : '#b8892c'
          }}
        >
          {titleRight}
        </span>
      </Header>
    );
  }

  renderDescriptions() {
    const { descriptions } = this.props;
    return descriptions.map(description => (
      <Header
        key={description}
        as="h3"
        style={{
          color: '#43525A',
          fontWeight: '400',
          fontSize: '1.1em',
          lineHeight: '1.5em'
        }}
      >
        {description}
      </Header>
    ));
  }

  renderDesktopView() {
    const { description, imagePosition } = this.props;
    return (
      <Responsive minWidth={1200}>
        <Container style={{ margin: '12em 0' }}>
          <Segment vertical>
            <Grid stackable verticalAlign="middle">
              {this.renderBox()}
              {this.renderInitial()}
              <Grid.Row style={{ padding: '0' }}>
                {imagePosition === 'left' && (
                  <Grid.Column
                    width={5}
                    style={{ padding: '0', margin: '0', marginTop: '-5em' }}
                  >
                    {this.renderImage()}
                  </Grid.Column>
                )}
                <Grid.Column width={11}>
                  <div style={{ maxWidth: '700px', padding: '40px' }}>
                    {this.renderTitle()}
                    {description || this.renderDescriptions()}
                  </div>
                </Grid.Column>
                {imagePosition === 'right' && (
                  <Grid.Column
                    width={5}
                    style={{ padding: '0', margin: '0', marginTop: '-5em' }}
                  >
                    {this.renderImage()}
                  </Grid.Column>
                )}
              </Grid.Row>
            </Grid>
          </Segment>
        </Container>
      </Responsive>
    );
  }

  // eslint-disable-next-line
  renderMobileView() {
    const { description } = this.props;
    return (
      <Responsive maxWidth={1199}>
        <Container style={{ margin: '5em 0' }}>
          <Segment vertical>
            <Grid stackable verticalAlign="top">
              <Grid.Row style={{ padding: '0' }}>
                <Grid.Column width={6} style={{ padding: '0', margin: '0' }}>
                  {this.renderImage(false)}
                </Grid.Column>
                <Grid.Column width={9}>
                  <div style={{ padding: '20px' }}>
                    {this.renderTitle(false)}
                    {description || this.renderDescriptions()}
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </Container>
      </Responsive>
    );
  }

  render() {
    return (
      <div>
        {this.renderMobileView()}
        {this.renderDesktopView()}
      </div>
    );
  }
}
