import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Responsive } from 'semantic-ui-react';

const DESKTOP_MIN_WIDTH = 1200; // Min 1200px

export default class DesktopView extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired
  };

  render() {
    const { children } = this.props;

    return (
      <Responsive minWidth={DESKTOP_MIN_WIDTH}>
        {children}
      </Responsive>
    );
  }
}
