import React, { Component } from 'react';
import { Container, Header, Image, Grid } from 'semantic-ui-react';

// eslint-disable-next-line
class Logos extends Component {
  // eslint-disable-next-line class-methods-use-this
  get title() {
    return (
      <Header
        as="h1"
        style={{ textAlign: 'center', margin: '7em 1em 5em', color: '#626262' }}
      >
        Our <span style={{ color: '#b8892c' }}>strategies</span> are used in
        some of the best-known companies
      </Header>
    );
  }

  // eslint-disable-next-line class-methods-use-this
  renderLogo(imageName, style) {
    return (
      <Grid.Column
        style={{
          display: 'grid',
          verticalAlign: 'middle',
          justifyContent: 'center',
          width: 'auto'
        }}
      >
        <Image src={`/static/images/logo/${imageName}.png`} style={style} />
      </Grid.Column>
    );
  }

  render() {
    return (
      <Container style={{ margin: '15em auto' }}>
        {/* {this.title} */}
        <Grid relaxed stackable textAlign="center">
          <Grid.Row columns={4} style={{ margin: '2em auto' }}>
            {this.renderLogo('silverlake')}
            {this.renderLogo('malaysia_airline')}
            {this.renderLogo('unitar')}
            {this.renderLogo('bank_islam')}
          </Grid.Row>
          <Grid.Row columns={4} style={{ margin: '2em auto' }}>
            {this.renderLogo('mmc')}
            {this.renderLogo('prima_baguz')}
            {this.renderLogo('eco')}
            {this.renderLogo('orkim')}
          </Grid.Row>
          <Grid.Row columns={4} style={{ margin: '2em auto' }}>
            {this.renderLogo('m')}
            {this.renderLogo('air_selangor', {
              padding: '10px',
              margin: 'auto'
            })}
            {this.renderLogo('uda')}
            {this.renderLogo('airasia')}
          </Grid.Row>
          <Grid.Row columns={4} style={{ margin: '2em auto' }}>
            {this.renderLogo('axiata')}
            {this.renderLogo('uem_edgenta')}
            {this.renderLogo('uem_sunrise')}
            {this.renderLogo('rasma')}
          </Grid.Row>
          <Grid.Row columns={4} style={{ margin: '2em auto' }}>
            {this.renderLogo('cenergi')}
            {this.renderLogo('roche')}
          </Grid.Row>
        </Grid>
      </Container>
    );
  }
}

export default Logos;
