import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Segment } from 'semantic-ui-react';
import DesktopView from '../Responsive/DesktopView';
import Heading from '../Heading';

const SEGMENT_STYLE = {
  minHeight: 1000,
  padding: '1em',
  border: 'none',
  boxShadow: 'none',
  backgroundImage: 'url("static/images/network.png")',
  backgroundSize: 'cover',
  backgroundPosition: 'bottom'
};

export default class DesktopContainer extends PureComponent {
  static propTypes = {
    children: PropTypes.node
  };

  render() {
    const { children } = this.props;

    return (
      <DesktopView>
        <Segment style={SEGMENT_STYLE}>
          <Heading />
        </Segment>
        {children}
      </DesktopView>
    );
  }
}
