import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Segment } from 'semantic-ui-react';
import MobileView from '../Responsive/MobileView';
import Heading from '../Heading';

const SEGMENT_STYLE = {
  minHeight: 1000,
  padding: '1em',
  border: 'none',
  boxShadow: 'none',
  backgroundImage: 'url("static/images/network.png")',
  backgroundSize: 'cover',
  backgroundPosition: 'bottom'
};

export default class MobileContainer extends PureComponent {
  static propTypes = {
    children: PropTypes.node
  };

  render() {
    const { children } = this.props;

    return (
      <MobileView>
        <Segment style={SEGMENT_STYLE}>
          <Heading mobile />
        </Segment>
        { children }
      </MobileView>
    );
  }
}
