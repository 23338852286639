import React from 'react';
import { Header } from 'semantic-ui-react';
import { Banner, ChartStory, Story } from '../../components';

const Ingenium = () => (
  <div>
    {/* Logo */}
    <Story
      margin="-10em 0 10em"
      objectFit="contain"
      imageSrc="static/images/network-diagram.png"
      imagePosition="right"
      title="A collaborative network of passionate human resources professionals"
      description={(
        <Header
          as="h3"
          style={{
            color: '#606b71',
            fontWeight: '400',
            fontSize: '1.2em',
            lineHeight: '1.7em'
          }}
        >
          We are a <strong>collaborative network</strong> of passionate human
          resources professionals working together to offer innovative
          organisational development & human capital solutions to our clients.
        </Header>
      )}
    />
    {/* <ScrollableAnchor id="ingenium"> */}
    <Story
      imageSrc="static/images/sample3.jpg"
      imageHeight="400px"
      imagePosition="right"
      title="We offer the best capabilities to all our clients"
      description={(
        <div>
          <Header
            as="h3"
            style={{
              color: '#606b71',
              fontWeight: '400',
              fontSize: '1.2em',
              lineHeight: '1.7em'
            }}
          >
            Across our network, we have{' '}
            <strong>
              deep & diverse HR expertise & solid industry knowledge
            </strong>{' '}
            including financial services, telecommunications, energy,
            hospitality & services.
          </Header>
          <Header
            as="h3"
            style={{
              color: '#606b71',
              fontWeight: '400',
              fontSize: '1.2em',
              lineHeight: '1.7em'
            }}
          >
            Our strengths-based <strong>collaboration philosophy</strong> is
            centred upon continually building on our ability to offer the best
            capabilities to all our clients.
          </Header>
          <Header
            as="h3"
            style={{
              color: '#606b71',
              fontWeight: '400',
              fontSize: '1.2em',
              lineHeight: '1.7em'
            }}
          >
            Every <strong>Ingenium</strong> associate has{' '}
            <strong>at least 18 years experience</strong> within their
            respective fields in HR and/or consulting.
          </Header>
        </div>
      )}
    />
    {/* </ScrollableAnchor> */}
    <Story
      imageSrc="static/images/sample1.jpg"
      imagePosition="left"
      title="Strategic Partnerships"
      description={(
        <div>
          <Header
            as="h3"
            style={{
              color: '#606b71',
              fontWeight: '400',
              fontSize: '1.2em',
              lineHeight: '1.7em'
            }}
          >
            We provide a single point of reference for all your talent &
            organisational development, HR service delivery and operational
            support requirements.
          </Header>
          <Header
            as="h3"
            style={{
              color: '#606b71',
              fontWeight: '400',
              fontSize: '1.2em',
              lineHeight: '1.7em'
            }}
          >
            Our collaboration philosophy <strong>extends</strong> beyond our
            internal network. We work with established business partners who
            have strong brands and expertise in their respective fields, giving
            us an <strong>unparalleled solutioning edge</strong>.
          </Header>
          <Header
            as="h3"
            style={{
              color: '#606b71',
              fontWeight: '400',
              fontSize: '1.2em',
              lineHeight: '1.7em'
            }}
          >
            Carefully cultivated business partnerships have enabled us to{' '}
            <strong>curate innovative & practical solutions</strong> for our
            clients extensively across the HR & organisational development value
            chain.
          </Header>
          <Header
            as="h3"
            style={{
              color: '#606b71',
              fontWeight: '400',
              fontSize: '1.2em',
              lineHeight: '1.7em'
            }}
          >
            Ingenium is proud to partner with : Aon | Optimal Consulting |
            MindMerge | Genashtim
          </Header>
        </div>
      )}
    />
    {/* Ingenium chart */}
    <div style={{ margin: '120px 0' }}>
      <Banner
        imageSrc="/static/images/sample6.jpg"
        title="Pulling seamlessly from our internal network of consultants and business
        partners, we are uniquely able to design integrated, fit-for-purpose
        solutions for our clients"
        bigTitle
      />
    </div>

    <Header
      as="h1"
      style={{ textAlign: 'center', margin: 0, color: '#626262' }}
    >
      Our <span style={{ color: '#b8892c' }}>Service</span> Offerings
    </Header>

    <ChartStory />

    <Story
      imageSrc="static/images/sample2.jpg"
      imageHeight="400px"
      imagePosition="left"
      title="A client centred philosophy"
      description={(
        <div>
          <Header
            as="h3"
            style={{
              color: '#606b71',
              fontWeight: '400',
              fontSize: '1.2em',
              lineHeight: '1.7em'
            }}
          >
            We apply a <strong>pragmatic & co-creative approach</strong> to
            designing solutions which are scaled to fit every unique set of
            client requirements.
          </Header>
          <Header
            as="h3"
            style={{
              color: '#606b71',
              fontWeight: '400',
              fontSize: '1.2em',
              lineHeight: '1.7em'
            }}
          >
            Our hands on, highly involved client interfacing style distinguishes
            us and allows us a{' '}
            <strong>greater intimacy & grasp of the issues</strong> impacting
            design & implementation.
          </Header>
          <Header
            as="h3"
            style={{
              color: '#606b71',
              fontWeight: '400',
              fontSize: '1.2em',
              lineHeight: '1.7em'
            }}
          >
            We field <strong>senior, tenured resources</strong> to ensure our
            clients derive the benefits of our{' '}
            <strong>consultants’ experience</strong>.
          </Header>
        </div>
      )}
    />
    <Story
      imageSrc="static/images/sample4.jpg"
      imageHeight="400px"
      imagePosition="left"
      title="Our core values"
      description={(
        <div>
          <Header
            as="h3"
            style={{
              color: '#606b71',
              fontWeight: '400',
              fontSize: '1.2em',
              lineHeight: '1.7em'
            }}
          >
            We exercise <strong>uncompromising integrity & transparency</strong>{' '}
            in everything we do and in all decisions we make.
          </Header>
          <Header
            as="h3"
            style={{
              color: '#606b71',
              fontWeight: '400',
              fontSize: '1.2em',
              lineHeight: '1.7em'
            }}
          >
            We fully embrace the{' '}
            <strong>true spirit of partnership & collaboration</strong>, always{' '}
            <strong>considering and respecting</strong> our clients, partners
            and consultants.
          </Header>
          <Header
            as="h3"
            style={{
              color: '#606b71',
              fontWeight: '400',
              fontSize: '1.2em',
              lineHeight: '1.7em'
            }}
          >
            We will always front the{' '}
            <strong>most optimal resources & capabilities</strong> we have in
            all our engagements.
          </Header>
        </div>
      )}
    />
  </div>
);

export default Ingenium;
