import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Responsive } from 'semantic-ui-react';

const MOBILE_MAX_WIDTH = 1199; // Max 1199px

export default class MobileView extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired
  };

  render() {
    const { children } = this.props;

    return (
      <Responsive maxWidth={MOBILE_MAX_WIDTH}>
        {children}
      </Responsive>
    );
  }
}
