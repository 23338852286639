import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Header, Segment, Image, Responsive, Grid } from 'semantic-ui-react';

export default class ReviewBanner extends PureComponent {
  static propTypes = {
    imageSrc: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    bigTitle: PropTypes.bool
  };

  renderTitle() {
    const { title, bigTitle } = this.props;
    const style = {
      fontWeight: bigTitle ? '100' : '500',
      color: bigTitle ? 'white' : '#43525A',
      fontSize: bigTitle ? '2.9em' : 'auto',
      margin: bigTitle ? '30px' : 'auto',
      background: 'rgba(138, 72, 3, 0.75)',
      padding: '20px'
    };
    return (
      <Header as="h1" style={style}>
        {title}
      </Header>
    );
  }

  // eslint-disable-next-line
  renderReview() {
    return (
      <div
        style={{
          maxWidth: '1000px',
          display: 'inline-block',
          margin: '70px 20px 50px'
        }}
      >
        <span
          style={{
            fontFamily: 'sans-serif',
            fontSize: '5em',
            textAlign: 'left',
            display: 'block'
          }}
        >
          “
        </span>
        Our partnership with Ingenium is on a different level of professionalism
        and responsiveness. Ingenium consultants are very well experienced HR
        experts and they delivered the job excellently as they also customized
        their services to fit our needs.
        <span
          style={{
            fontFamily: 'sans-serif',
            fontSize: '5em',
            textAlign: 'right',
            display: 'block',
            marginTop: '0.4em'
          }}
        >
          ”
        </span>
        <p
          style={{
            textAlign: 'right',
            fontSize: '0.7em',
            fontWeight: 'bold'
          }}
        >
          - HR Vice President
          <Image
            src="/static/images/logo/magic_nomargin.png"
            style={{
              display: 'inline-block',
              textAlign: 'right',
              maxHeight: '25px',
              marginLeft: '15px',
              objectFit: 'contain',
              objectPosition: 'center'
            }}
          />
        </p>
      </div>
    );
  }

  renderDesktopView() {
    const { bigTitle, imageSrc } = this.props;
    return (
      <Responsive minWidth={1200}>
        <Segment
          vertical
          style={{
            margin: '0 auto 5em',
            // maxWidth: '1400px',
            minHeight: '500px',
            backgroundImage: `url(${imageSrc})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundAttachment: 'fixed',
            border: 'none'
          }}
        >
          <Grid stackable verticalAlign="middle">
            <Grid.Row style={{ padding: '0' }}>
              <Grid.Column width={16}>
                <div style={{ padding: '40px 0' }}>
                  <Header
                    as="h3"
                    style={{
                      display: 'inline-block',
                      fontWeight: bigTitle ? '400' : '500',
                      color: bigTitle ? 'white' : '#43525A',
                      fontSize: bigTitle ? '1.7em' : 'auto',
                      margin: bigTitle ? '30px 50px' : 'auto',
                      width: '-webkit-fill-available',
                      // padding: '3em 10em',
                      textAlign: 'center',
                      background: 'rgba(138, 72, 3, 0.75)'
                    }}
                  >
                    {this.renderReview()}
                  </Header>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </Responsive>
    );
  }

  renderMobileView() {
    const { bigTitle, imageSrc } = this.props;
    return (
      <Responsive maxWidth={1199}>
        <Segment
          vertical
          style={{
            margin: '0 auto 5em',
            // maxWidth: '1400px',
            minHeight: '500px',
            backgroundImage: `url(${imageSrc})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundAttachment: 'fixed',
            border: 'none'
          }}
        >
          <Grid stackable verticalAlign="middle">
            <Grid.Row style={{ padding: '0' }}>
              <Grid.Column width={16}>
                <div style={{ padding: '40px 0' }}>
                  <Header
                    as="h3"
                    style={{
                      display: 'inline-block',
                      fontWeight: bigTitle ? '400' : '500',
                      color: bigTitle ? 'white' : '#43525A',
                      fontSize: bigTitle ? '1.5em' : 'auto',
                      margin: bigTitle ? '10px 25px' : 'auto',
                      width: '-webkit-fill-available',
                      // padding: '3em 10em',
                      textAlign: 'center',
                      background: 'rgba(138, 72, 3, 0.75)'
                    }}
                  >
                    {this.renderReview()}
                  </Header>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </Responsive>
    );
  }

  render() {
    return (
      <div>
        {this.renderDesktopView()}
        {this.renderMobileView()}
      </div>
    );
  }
}
