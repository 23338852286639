import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Image, Container, Header, Segment, Responsive, Grid } from 'semantic-ui-react';

export default class Story extends PureComponent {
  static propTypes = {
    objectFit: PropTypes.string,
    imageSrc: PropTypes.string.isRequired,
    imageHeight: PropTypes.string,
    imagePosition: PropTypes.oneOf(['left', 'right']),
    title: PropTypes.string.isRequired,
    margin: PropTypes.string,
    descriptions: PropTypes.arrayOf(PropTypes.string),
    description: PropTypes.node
  };

  static defaultProps = {
    margin: '10em 0',
    objectFit: 'cover',
    imagePosition: 'left',
    imageHeight: '500px'
  };

  renderTitle() {
    const { title } = this.props;
    return (
      <Header
        as="h1"
        style={{ color: '#43525A', fontWeight: 900, marginBottom: '1em' }}
      >
        {title}
      </Header>
    );
  }

  renderDescriptions() {
    const { descriptions } = this.props;
    return descriptions.map(description => (
      <Header
        key={description}
        as="h3"
        style={{
          color: '#43525A',
          fontWeight: '400',
          fontSize: '1.2em',
          lineHeight: '1.5em'
        }}
      >
        {description}
      </Header>
    ));
  }

  renderImage(desktop = true) {
    const { imageSrc, imageHeight, objectFit } = this.props;
    return (
      <Image
        src={imageSrc}
        style={{
          padding: desktop ? '0 0 0 14px' : '0', // left padding
          margin: '0',
          minHeight: desktop ? imageHeight : '300px',
          objectFit,
          objectPosition: 'center'
        }}
      />
    );
  }

  renderDesktopView() {
    const { margin, description, imagePosition } = this.props;
    return (
      <Responsive minWidth={1200}>
        <Container style={{ margin }}>
          <Segment vertical>
            <Grid stackable verticalAlign="middle">
              <Grid.Row style={{ padding: '0' }}>
                {imagePosition === 'left' && (
                  <Grid.Column width={7} style={{ padding: '0', margin: '0' }}>
                    {this.renderImage()}
                  </Grid.Column>
                )}
                {imagePosition === 'left' && <Grid.Column width={1} />}
                <Grid.Column width={8}>
                  <div style={{ maxWidth: '800px', padding: '20px' }}>
                    {this.renderTitle()}
                    {description || this.renderDescriptions()}
                  </div>
                </Grid.Column>
                {imagePosition === 'right' && <Grid.Column width={1} />}
                {imagePosition === 'right' && (
                  <Grid.Column width={7} style={{ padding: '0', margin: '0' }}>
                    {this.renderImage()}
                  </Grid.Column>
                )}
              </Grid.Row>
            </Grid>
          </Segment>
        </Container>
      </Responsive>
    );
  }

  renderMobileView() {
    const { margin, description } = this.props;
    return (
      <Responsive maxWidth={1199}>
        <Container style={{ margin }}>
          <Segment vertical>
            <Grid stackable verticalAlign="top">
              <Grid.Row style={{ padding: '0' }}>
                <Grid.Column width={7} style={{ padding: '0', margin: '0' }}>
                  {this.renderImage(false)}
                </Grid.Column>
                <Grid.Column width={8}>
                  <div style={{ maxWidth: '800px', padding: '0' }}>
                    {this.renderTitle()}
                    {description || this.renderDescriptions()}
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </Container>
      </Responsive>
    );
  }

  render() {
    return (
      <div>
        {this.renderDesktopView()}
        {this.renderMobileView()}
      </div>
    );
  }
}
