import React from 'react';
import { Image, Container, Segment, Grid } from 'semantic-ui-react';
import Section from './Section';

export default () => (
  <Container style={{ margin: '1em 0', padding: '20px' }}>
    <Segment vertical>
      <Grid stackable verticalAlign="middle">
        <Grid.Row style={{ padding: '0' }}>
          {/* Image left column */}
          <Grid.Column width={7} style={{ padding: '20px', margin: '0' }}>
            <Image
              src="static/images/chart.png"
              style={{ objectFit: 'cover', objectPosition: 'center' }}
            />
          </Grid.Column>
          {/* Gap */}
          <Grid.Column width={1} />
          {/* Content */}
          <Grid.Column width={8} style={{ padding: '40px' }}>
            <Section
              title="Culture & Engagement"
              description="Employee Engagement, EVP & Employer Branding, Workforce Transformation"
            />
            <Section
              title="More from People"
              description="Talent Management, Organisation Design, Workforce Planning, Performance Leadership, Rewards Optimisation"
            />
            <Section
              title="Sustainable Capability"
              description="Learning Strategies, Leadership Development & Executive Coaching, e-Learning Technologies"
            />
            <Section
              title="Under Better HR"
              description="HR Operating Modal & Service Delivery Strategy, HR Process Enhancement & Technology Enablement"
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  </Container>
);
