import React from 'react';
import { Header } from 'semantic-ui-react';
import { PersonStory } from '../../components';

const IMAGE_SRC = '/static/images/person/tham_wai_yee.jpg';
const INITIAL = 'T';
const LEFT_NAME = 'THAM';
const RIGHT_NAME = 'WAI YEE';

const DESCRIPTION_STYLE = {
  color: '#43525A',
  fontWeight: '400',
  fontSize: '1.1em',
  lineHeight: '1.5em'
};

export default () => (
  <PersonStory
    initial={INITIAL}
    imageSrc={IMAGE_SRC}
    imagePosition="left"
    title={LEFT_NAME}
    titleRight={RIGHT_NAME}
    titleLeftMargin="-1em"
    description={(
      <div>
        <Header as="h3" style={DESCRIPTION_STYLE}>
          Wai Yee is a passionate HR professional with over 19 years
          of consulting experience. Her recent role was as a Regional
          Practice Director of Organisational Survey & Insights
          Practice in Willis Towers Watson. She was responsible for
          the business development and project delivery for 6 markets
          in Southeast Asia i.e., Indonesia, Malaysia, Philippines,
          Singapore, Thailand, and Vietnam.
        </Header>
        <Header as="h3" style={DESCRIPTION_STYLE}>
          Wai Yee has extensive experience in survey and
          measurement-related work in Asia and cross-cultural business
          environments. As an experienced professional in the
          interpretation of employee and management views on
          organisational culture, Wai Yee has sound understanding of
          &nbsp;
          <strong>
            the drivers of employee engagement and unique insights
          </strong>
          &nbsp;
          into the use of various diagnostic tools for organisational
          change.
        </Header>
        <Header as="h3" style={DESCRIPTION_STYLE}>
          Her experience in other areas include
          &nbsp;
          <strong>
            Change Management, Organisation Development, Talent
            Management & Human Capital/Workforce Transformation,
            including in M&A and Transformation situations
          </strong>
          .
        </Header>
        <Header as="h3" style={DESCRIPTION_STYLE}>
          Wai Yee graduated from Monash University, Australia with a
          Bachelor of Business.
        </Header>
      </div>
    )}
  />
);
