export { default as Footer } from './Footer';
export { default as DesktopContainer } from './DesktopContainer';
export { default as Heading } from './Heading';
export { default as MobileContainer } from './MobileContainer';
export { default as Newsletter } from './Newsletter';
export { default as Banner } from './Banner';
export { default as Story } from './Story';
export { default as PersonStory } from './PersonStory';
export { default as Logos } from './Logos';
export { default as TwoSectionsSegment } from './TwoSectionsSegment';
export { default as Review } from './Review';
export { default as ChartStory } from './ChartStory';
export { default as ReviewBanner } from './ReviewBanner';
