import React from 'react';

import TwoSectionsDesktop from './Desktop';
import TwoSectionsMobile from './Mobile';

const PICHA_DESCRIPTION = `
  PichaEats is an impact driven enterprise that empowers
  marginalised groups in Malaysia through a sustainable food and
  catering business. They deliver and cater authentic traditional
  meals from different cultures prepared by refugee families from
  various countries to companies, events & organisations.
`;
const PICHA_LINK = 'https://www.pichaproject.com/';
const PICHA_IMG_MOBILE = '/static/images/logo/pichaeats.png';
const PICHA_IMG_DESKTOP = '/static/images/logo/pichaeats_nomargin.png';

const SORO_DESCRIPTION = `
  The Soroptimist Malaysia programme, SEEED Jobs for Life, which
  commenced in February 2015 and is aimed primarily at the 18-25 age
  group, has inspired and motivated young people to achieve mental,
  emotional and physical fitness and built confidence and
  self-esteem, values, work ethics and readiness for employment or
  to start a small business.
`;
const SORO_LINK = 'http://soroptimistmalaysia.org/';
const SORO_IMG_MOBILE = '/static/images/logo/soroptimist_international.png';
const SORO_IMG_DESKTOP = '/static/images/logo/soroptimist_international_nomargin.png';


export default () => (
  <div>
    <TwoSectionsMobile
      leftLogoSrc={PICHA_IMG_MOBILE}
      leftDescription={PICHA_DESCRIPTION}
      leftLinkHref={PICHA_LINK}
      rightLogoSrc={SORO_IMG_MOBILE}
      rightDescription={SORO_DESCRIPTION}
      rightLinkHref={SORO_LINK}
    />
    <TwoSectionsDesktop
      leftLogoSrc={PICHA_IMG_DESKTOP}
      leftDescription={PICHA_DESCRIPTION}
      leftLinkHref={PICHA_LINK}
      rightLogoSrc={SORO_IMG_DESKTOP}
      rightDescription={SORO_DESCRIPTION}
      rightLinkHref={SORO_LINK}
    />
  </div>
);
