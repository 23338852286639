import React, { Component } from 'react';
import { Transition } from 'semantic-ui-react';

import Banner from './Banner';
import Selina from './Selina';
import ThamWaiYee from './ThamWaiYee';
import YapSweePheng from './YapSweePheng';

const TRANSITION_STYLE = 'fade down';
const TRANSITION_DURATION = 2000; // 2 seconds

export default class Team extends Component {
  state = {
    visible: true
  }

  handleToggle() {
    const { visible } = this.state;
    this.setState({ visible: !visible });
  }

  render() {
    const { visible } = this.state;

    return (
      <div>
        <Banner />
        <Transition visible={visible} animation={TRANSITION_STYLE} duration={TRANSITION_DURATION}>
          <div style={{ paddingTop: '2em' }}>
            <Selina />
            <ThamWaiYee />
            <YapSweePheng />
          </div>
        </Transition>
      </div>
    );
  }
}
