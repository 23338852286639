import React, { PureComponent } from 'react';
import { Button, Input, Container, Header, Segment } from 'semantic-ui-react';
import MailchimpSubscribe from 'react-mailchimp-subscribe';

const MAILCHIMP_URL = '//team-ingenium.us19.list-manage.com/subscribe/post?u=2c2892ac0cf18ba0a2fda2111&amp;id=1ace65c073';

export default class Newsletter extends PureComponent {
  state = {
    isEmpty: false,
    email: ''
  };

  render() {
    const { isEmpty, email } = this.state;
    return (
      <Segment
        vertical
        style={{
          margin: '100px 40px',
          padding: '0',
          background: 'none',
          border: 'none'
        }}
      >
        <Container>
          <div style={{ maxWidth: '800px' }}>
            <Header as="h1" style={{ color: '#43525A', fontWeight: '500' }}>
              Chat with us!
            </Header>
            <p style={{ color: '#43525A', fontSize: '1.2em' }}>
              We are proud of our innovative solutioning capability and how we
              always customise interventions to be practical and fit for your
              specific purpose. If you would like to discuss any people &
              workplace imperatives you have at work, please reach out!
            </p>
            <Header as="h3" style={{ color: '#28A5BE', fontWeight: '500' }}>
              Just drop any one of us an email. We are always ready to chat and
              share ideas.
            </Header>
            <MailchimpSubscribe
              url={MAILCHIMP_URL}
              render={({ subscribe, status, message }) => (
                <div>
                  <Input
                    size="large"
                    type="email"
                    placeholder="Your email address"
                    onChange={(e, d) => this.setState({ email: d.value })}
                    style={{ margin: '20px 0' }}
                    action={(
                      <Button
                        color="blue"
                        style={{ borderRadius: '0' }}
                        onClick={() => {
                          if (email === '') {
                            this.setState({ isEmpty: true });
                          } else {
                            this.setState({ isEmpty: false });
                            subscribe({ EMAIL: email });
                          }
                        }}
                      >
                        Submit
                      </Button>
                    )}
                  />
                  {isEmpty ? (
                    <div style={{ color: 'blue' }}>
                      Please fill in the email address
                    </div>
                  ) : (
                    <div>
                      {status === 'sending' && (
                        <div style={{ color: 'blue' }}>Sending...</div>
                      )}
                      {status === 'error' && (
                        <div style={{ color: 'red' }}>
                          {message.startsWith('0 - ')
                            ? message.substring(4)
                            : message}
                        </div>
                      )}
                      {status === 'success' && (
                        <div style={{ color: 'green' }}>{message}</div>
                      )}
                    </div>
                  )}
                </div>
              )}
            />
          </div>
        </Container>
      </Segment>
    );
  }
}
