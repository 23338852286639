import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Image, Container, Header, Segment, Grid, Responsive } from 'semantic-ui-react';

class Review extends PureComponent {
  static propTypes = {
    logoSrc: PropTypes.string.isRequired,
    logoPosition: PropTypes.oneOf(['left', 'right']),
    review: PropTypes.string.isRequired,
    reviewer: PropTypes.string,
    imageStyle: PropTypes.objectOf(PropTypes.any)
  };

  static defaultProps = {
    logoPosition: 'left',
    imageStyle: {}
  };

  renderReviewer() {
    const { reviewer } = this.props;
    return (
      <Header as="h4" style={{ fontWeight: '500', color: '#43525A' }}>
        {reviewer}
      </Header>
    );
  }

  renderReview() {
    const { review } = this.props;
    return (
      <Header
        as="h3"
        style={{
          color: '#43525A',
          fontSize: '1.3em',
          lineHeight: '1.5em'
        }}
      >
        {review}
      </Header>
    );
  }

  renderImage() {
    const { imageStyle, logoSrc } = this.props;
    return (
      <Image
        src={logoSrc}
        style={{
          padding: '30px',
          margin: '0 auto',
          maxHeight: '450px',
          objectFit: 'contain',
          objectPosition: 'center',
          ...imageStyle
        }}
      />
    );
  }

  renderDesktopView() {
    const { logoPosition } = this.props;
    return (
      <Responsive minWidth={1200}>
        <Container style={{ margin: '1em 0' }}>
          <Segment vertical>
            <Grid stackable verticalAlign="middle">
              <Grid.Row style={{ padding: '0' }}>
                {logoPosition === 'left' && (
                  <Grid.Column width={6} style={{ padding: '0', margin: '0' }}>
                    {this.renderImage()}
                  </Grid.Column>
                )}
                <Grid.Column width={10}>
                  <div style={{ maxWidth: '1200px', padding: '40px' }}>
                    {this.renderReview()}
                    {this.renderReviewer()}
                  </div>
                </Grid.Column>
                {logoPosition === 'right' && (
                  <Grid.Column width={6} style={{ padding: '0', margin: '0' }}>
                    {this.renderImage()}
                  </Grid.Column>
                )}
              </Grid.Row>
            </Grid>
          </Segment>
        </Container>
      </Responsive>
    );
  }

  // eslint-disable-next-line
  renderMobileView() {
    return (
      <Responsive maxWidth={1199}>
        <Container style={{ margin: '1em 0' }}>
          <Segment vertical>
            <Grid stackable verticalAlign="middle">
              <Grid.Row style={{ padding: '0' }}>
                <Grid.Column width={8} style={{ padding: '0', margin: '0' }}>
                  {this.renderImage()}
                </Grid.Column>
                <Grid.Column width={8}>
                  <div style={{ padding: '20px' }}>
                    {this.renderReview()}
                    {this.renderReviewer()}
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </Container>
      </Responsive>
    );
  }

  render() {
    return (
      <div>
        {this.renderMobileView()}
        {this.renderDesktopView()}
      </div>
    );
  }
}

export default Review;
