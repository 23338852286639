import React, { Component } from 'react';
import { Container, Grid, Header, Segment, Image } from 'semantic-ui-react';
import './Footer.css';

class Footer extends Component {
  // eslint-disable-next-line class-methods-use-this
  get year() {
    return new Date().getFullYear();
  }

  render() {
    return (
      <Segment
        vertical
        style={{
          margin: '40px 40px 0',
          padding: '5em 0em',
          background: 'none'
        }}
      >
        <Container>
          <Grid divided stackable>
            <Grid.Row>
              <Grid.Column width={4}>
                <Header as="h4">Office</Header>
                <p>
                  L28-03-03A
                  <br />
                  Level 28, PJ Exchange,
                  <br />
                  16A Persiaran Barat,
                  <br />
                  46050 Petaling Jaya Selangor,
                  <br />
                  Malaysia.
                </p>
              </Grid.Column>
              <Grid.Column width={4}>
                <Header as="h4">
                  Selina Chean
                  <Header.Subheader>Partner</Header.Subheader>
                </Header>
                <p>
                  E: &nbsp;
                  <a
                    href="mailto:selinachean.ingenium@gmail.com"
                    style={{ color: 'black', wordBreak: 'break-all' }}
                  >
                    selinachean.ingenium@gmail.com
                  </a>
                </p>
              </Grid.Column>
              <Grid.Column width={4}>
                <Header as="h4">
                  Tham Wai Yee
                  <Header.Subheader>Partner</Header.Subheader>
                </Header>
                <p>
                  E: &nbsp;
                  <a
                    href="mailto:waiyeetham.ingenium@gmail.com"
                    style={{ color: 'black', wordBreak: 'break-all' }}
                  >
                    waiyeetham.ingenium@gmail.com
                  </a>
                </p>
              </Grid.Column>
              <Grid.Column width={4}>
                <Header as="h4">
                  Yap Swee Pheng
                  <Header.Subheader>Partner</Header.Subheader>
                </Header>
                <p>
                  E: &nbsp;
                  <a
                    href="mailto:sweepheng.ingenium@gmail.com"
                    style={{ color: 'black', wordBreak: 'break-all' }}
                  >
                    sweepheng.ingenium@gmail.com
                  </a>
                </p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Image
            src="/static/images/logo_no_margin.png"
            size="small"
            style={{ margin: '7em auto 1em', maxWidth: '100px' }}
          />
          <p
            style={{
              padding: '10px 20px 30px',
              textAlign: 'center',
              fontSize: '0.8em',
              color: '#b5b5b5'
            }}
          >
            Copyright © {this.year} Ingenium. All rights reserved.
          </p>
        </Container>
      </Segment>
    );
  }
}

export default Footer;
