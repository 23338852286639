import React, { Component } from 'react';
import { Button, Transition } from 'semantic-ui-react';
import { Logos, Banner, Review } from '../../components';

export default class Reviews extends Component {
  state = {
    visible: true
  };

  handleToggle() {
    const { visible } = this.state;
    this.setState({ visible: !visible });
  }

  // NOTE this is currently disabled.
  // it can be used to display/hide the section.
  // eslint-disable-next-line
  renderReadmore() {
    const { visible } = this.state;
    return (
      <Button
        style={{ display: 'block', margin: '0 auto', background: 'none' }}
        content={visible ? '' : 'Read more'}
        onClick={() => this.handleToggle()}
      />
    );
  }

  render() {
    const { visible } = this.state;
    return (
      <div>
        <Review
          logoSrc="/static/images/logo/cenviro.png"
          logoPosition="left"
          reviewer="Chief Financial Officer, Cenviro Group"
          review="“Your solutions are very practical, tailored and customized to our people and environment. This demonstrates that you understand your client's needs and strive to meet them.”"
        />
        {/* {this.renderReadmore()} */}
        <Transition visible={visible} animation="fade down" duration={2000}>
          <div>
            <Review
              logoSrc="/static/images/logo/suruhanjaya_sekuriti.png"
              logoPosition="left"
              reviewer="SGM Human Capital Management & Organisational Effectiveness, Securities Commission of Malaysia"
              review="“The consultants’ experience and professionalism provided comfort to our managers in providing not only honest but meaningful feedback on our people practices. Ingenium's ability to synthesise the findings into action plans became a good foundation in enabling HR to review its HR practices”"
            />
            <Review
              logoSrc="/static/images/logo/xox.png"
              logoPosition="left"
              reviewer="Chief Transformation Officer, XOX"
              review="“It was a pleasure working with the Ingenium team. You and your team have set up a solid people management framework for XOX which we are continuing to implement and benefit from”"
            />
            <Review
              logoSrc="/static/images/logo/bam.png"
              logoPosition="left"
              reviewer="Morten Frost, Technical Director BAM"
              review="“It has been a real pleasure to work with you and your dedicated colleagues. Your company gave my presentation just the right edge. Together we succeeded building a case receiving much applause. Your ability to think out of the box, venture into unknown territory and make sense of it, was amazing. Thank you so much.”"
            />
            <Review
              logoSrc="/static/images/logo/ekuinas.png"
              logoPosition="left"
              reviewer="Nina Yassin, Director, Human Capital Ekuinas"
              review="“The Ingenium team were able to understand the needs our portfolio companies well. This is important for us given that these companies have a very strong entrepreneur culture. The team took their time to speak the employees, management, and owners of the companies to gather insights, which they then were able to tailor their approach in a way that connects with the employees and management of the companies.”"
            />
            {/* The rest of the clients */}
            <div style={{ margin: '120px 0' }}>
              <Banner
                imageSrc="/static/images/sample2.jpg"
                title="We have the privilege of working with some of the best known companies"
                bigTitle
              />
            </div>
            <Logos />
          </div>
        </Transition>
      </div>
    );
  }
}
