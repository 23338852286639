import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Button, Container, Image, Divider } from 'semantic-ui-react';
import MobileView from '../Responsive/MobileView';

const BUTTON_TEXT = 'Learn more';

export default class Mobile extends PureComponent {
  static propTypes = {
    leftLogoSrc: PropTypes.string.isRequired,
    leftDescription: PropTypes.string.isRequired,
    leftLinkHref: PropTypes.string.isRequired,
    rightLogoSrc: PropTypes.string.isRequired,
    rightDescription: PropTypes.string.isRequired,
    rightLinkHref: PropTypes.string.isRequired
  };

  renderLeftSection() {
    const { leftLogoSrc, leftDescription, leftLinkHref } = this.props;

    return (
      <div>
        <Image
          src={leftLogoSrc}
          style={{ margin: '100px auto 50px', maxWidth: '300px' }}
        />
        <p style={{ lineHeight: '1.5em', minHeight: '140px' }}>
          { leftDescription }
        </p>
        <Button
          color="brown"
          inverted
          style={{ borderRadius: '5em' }}
          href={leftLinkHref}
          target="_blank"
        >
          {BUTTON_TEXT}
        </Button>
      </div>
    );
  }

  renderRightSection() {
    const { rightLogoSrc, rightDescription, rightLinkHref } = this.props;

    return (
      <div>
        <Image
          src={rightLogoSrc}
          style={{
            display: 'inline-block',
            margin: '20px',
            maxWidth: '350px'
          }}
        />
        <p style={{ lineHeight: '1.5em', minHeight: '150px' }}>
          { rightDescription }
        </p>
        <Button
          color="violet"
          inverted
          style={{ borderRadius: '5em' }}
          href={rightLinkHref}
          target="_blank"
        >
          {BUTTON_TEXT}
        </Button>
      </div>
    );
  }

  render() {
    return (
      <MobileView>
        <Container style={{ textAlign: 'center', margin: '8em 0 3em', padding: '30px' }}>
          {this.renderLeftSection()}
          <Divider style={{ margin: '5em 3em 3em' }} />
          {this.renderRightSection()}
        </Container>
      </MobileView>
    );
  }
}
