import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Header } from 'semantic-ui-react';

import BannerDesktop from './Desktop';
import BannerMobile from './Mobile';

export default class Banner extends PureComponent {
  static propTypes = {
    imageSrc: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    bigTitle: PropTypes.bool
  };

  renderTitle(desktop = true) {
    const { title, bigTitle } = this.props;
    const desktopFontSize = bigTitle ? '2.5em' : '2em';
    const style = {
      fontWeight: bigTitle ? '4' : '500',
      color: bigTitle ? 'white' : 'white',
      fontSize: desktop ? desktopFontSize : '2em',
      margin: bigTitle ? '30px' : 'auto',
      background: 'rgba(138, 72, 3, 0.75)',
      padding: '20px'
    };

    return (
      <Header as="h1" style={style}>
        {title}
      </Header>
    );
  }

  render() {
    const { bigTitle, imageSrc } = this.props;
    return (
      <div>
        <BannerDesktop
          imageSrc={imageSrc}
          renderTitle={() => this.renderTitle()}
        />
        <BannerMobile
          bigTitle={bigTitle}
          imageSrc={imageSrc}
          renderTitle={bool => this.renderTitle(bool)}
        />
      </div>
    );
  }
}
