import React from 'react';
import ScrollableAnchor, { configureAnchors } from 'react-scrollable-anchor';
import { Divider } from 'semantic-ui-react';

import { Banner, Newsletter, Footer, ReviewBanner, TwoSectionsSegment } from '../../components';
import ResponsiveContainer from '../ResponsiveContainer';
import Reviews from '../Reviews';
import Team from '../Team';
import Ingenium from '../Ingenium';

// Offset all anchors by -60 to account for a fixed header
// and scroll more quickly than the default 400ms
configureAnchors({
  offset: -60,
  scrollDuration: 2500
});

const REVIEW_BANNER_IMAGE = '/static/images/sample6.jpg';
const REVIEW_BANNER_CONTENT = `
  “Our partnership with Ingenium is on a different level of professionalism and
  responsiveness. Ingenium consultants are very well experienced HR experts and
  they delivered the job excellently as they also customized their services to
  fit our needs.” HR Vice President, MaGIC
`;

const CSR_BANNER_IMAGE = '/static/images/sample7.jpg';
const CSR_BANNER_CONTENT = `
  We also do our part to ensure that we are giving back by assisting organisations
  in the areas of HR advisory, training & coaching as they operate to contribute
  to impactful social causes.
`;

export default () => (
  <ResponsiveContainer>
    {/* Ingenium Story */}
    <Ingenium />
    {/* Team Story */}
    <Team />
    {/* Review Banner */}
    <ReviewBanner
      bigTitle
      imageSrc={REVIEW_BANNER_IMAGE}
      title={REVIEW_BANNER_CONTENT}
    />
    {/* Reviews */}
    <Reviews />
    {/* CSR */}
    <Banner
      imageHeight="200px"
      imageSrc={CSR_BANNER_IMAGE}
      title={CSR_BANNER_CONTENT}
    />
    <TwoSectionsSegment />
    {/* Gap */}
    <Divider />
    {/* Scrollable anchor used with "#" on the URL or button href */}
    <ScrollableAnchor id="newsletter">
      <Newsletter />
    </ScrollableAnchor>
    {/* Footer */}
    <Footer />
  </ResponsiveContainer>
);
